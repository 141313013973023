<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-form class="custom-form" @submit.prevent="cashTransationForm">
                    <v-card :elevation="1" color="blue lighten-4">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-card-text class="py-0">
                                    <v-row dense>
                                        <v-col cols="3">
                                            <v-row no-gutters>
                                                <v-col cols="3">Type</v-col>
                                                <v-col cols="9">
                                                    <v-combobox 
                                                        v-model="searchType"
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :items="['All', 'Payment Type']"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="3" v-if="this.searchType == 'Payment Type'">
                                            <v-row no-gutters>
                                                <v-col cols="4">Transaction</v-col>
                                                <v-col cols="8">
                                                    <v-combobox 
                                                        dense
                                                        v-model="cashTransations.paymentType"
                                                        outlined
                                                        hide-details
                                                        :items="['Cash Payment', 'Cash Receive']"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="5">
                                            <v-row no-gutters>
                                                <v-col cols="2">Form Date</v-col>
                                                <v-col cols="4">
                                                    <v-menu>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                v-model="cashTransations.dateFrom"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                            <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="cashTransations.dateFrom"></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col cols="2" class="text-center">To Date</v-col>
                                                <v-col cols="4">
                                                    <v-menu>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                v-model="cashTransations.dateTo"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                            <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="cashTransations.dateTo"></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="1">
                                            <v-btn height="26px" type="submit" dark block color="light-blue darken-2" :loading="isLoading">Search</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-row dense v-if="show">
            <v-col cols="12">
                <v-btn small color="blue-grey lighten-4" @click.prevent="print">Print</v-btn>
            </v-col>
            <v-col cols="12" id="cashTransactionRecord">
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th>Transaction Date</th>
                                <th>Account Name</th>
                                <th>Type</th>
                                <th>Note</th>
                                <th>Received Amount</th>
                                <th>Paid Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(transaction, ind) in $store.getters['cashTransaction/cashTransactions']" :key="ind">
                                <td>{{ ind + 1 }}</td>
                                <td>{{ transaction.transaction_date }}</td>
                                <td>{{ transaction.transaction_account? transaction.transaction_account.account_name : ''}}</td>
                                <td>{{ transaction.transaction_type }}</td>
                                <td>{{ transaction.transaction_account? transaction.transaction_account.description : ''}}</td>
                                <td class="text-right">{{ transaction.received_amount }}</td>
                                <td class="text-right">{{ transaction.paid_amount }}</td>
                            </tr>
                            <tr>
                                <td colspan="5" class="text-right"><strong>Total</strong></td>
                                <td class="text-right"><strong>{{ totalReceive }}</strong></td>
                                <td class="text-right"><strong>{{ totalPaid }}</strong></td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    name: 'PresentEntry',
    data: ()=> ({
        date: new Date().toISOString().substr(0, 10),
        searchType: null,
        cashTransations: {
            paymentType: null,
            dateFrom: new Date().toISOString().substr(0, 10),
            dateTo: new Date().toISOString().substr(0, 10),
        },

        isLoading: false,
        show: false,

        apiBasePath: apiBaseURL
    }),

    computed: {
        totalReceive() {
            return this.$store.getters['cashTransaction/cashTransactions'].reduce((p, c) => { return +p + +c.received_amount }, 0)
        },

        totalPaid() {
            return this.$store.getters['cashTransaction/cashTransactions'].reduce((p,c) => { return +p + + c.paid_amount }, 0)
        }
    },

    created() {
        this.setStyle();
        this.getCompanyProfile();
    },

    methods: {
        getCompanyProfile() {
            this.$store.dispatch('companyProfile/getCompanyProfile')
        },

        cashTransationForm() {
            this.isLoading = true;
            if(this.searchType == 'All') {
                this.cashTransations.paymentType = null;
                this.$store.dispatch('cashTransaction/getCashTransactions', this.cashTransations)
                
            }
            
            let success = this.$store.dispatch('cashTransaction/getCashTransactions', this.cashTransations);
            
            this.isLoading = false

            this.show = true;
        },

        async print(){
            let invoiceContent = document.querySelector('#cashTransactionRecord').innerHTML;
            let printWindow = window.open('', 'PRINT', `width=${screen.width}, height=${screen.height}, left=0, top=0`);
            let ImagePath = this.apiBasePath +`/`+ this.$store.getters['companyProfile/company'].image;
            let companyName = this.$store.getters['companyProfile/company'].company_name;
            let address = this.$store.getters['companyProfile/company'].address;
            let contactUs = this.$store.getters['companyProfile/company'].contact_us;
            let email = this.$store.getters['companyProfile/company'].email;
            printWindow.document.write(`
                <!DOCTYPE html>
                <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <meta http-equiv="X-UA-Compatible" content="ie=edge">
                    <title>Investigation Payment Slip</title>
                    <style>
                    </style>
                </head>
                <body>
                    <div>
                        <div class="container">
                            <table style="width:100%;">
                                <thead>
                                    <tr>
                                        <td>
                                            <div class="custom-row">
                                                <div class="col-xs-2">
                                                    <img src="`+ImagePath+`" alt="Logo" style="height:90px; width:90%;object-fit: contain;" />
                                                </div>
                                                <div class="col-xs-10">
                                                    <strong style="font-size:18px;">${companyName}</strong><br>
                                                    <p style="white-space:pre-line;margin:0px">Address : ${address}</p>
                                                    <p style="margin:0px">Mobile : ${contactUs}</p>
                                                    <p style="margin:0px">E-mail : ${email}</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="custom-row">
                                                <div class="invoice-title">
                                                    Cash Transaction Record
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="custom-row">
                                                <div class="col-xs-12">
                                                    ${invoiceContent}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>
                                            <div style="width:100%;height:50px;">&nbsp;</div>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>    
                    
                </body>
                </html>
            `);
            
            let invoiceStyle = printWindow.document.createElement('style');
            invoiceStyle.innerHTML = this.style.innerHTML;
            printWindow.document.head.appendChild(invoiceStyle);
            printWindow.moveTo(0, 0);
            
            printWindow.focus();
            await new Promise(resolve => setTimeout(resolve, 1000));
            printWindow.print();
            printWindow.close();
        },

        setStyle(){
            this.style = document.createElement('style');
            this.style.innerHTML = `
                .custom-row {
                    width: 100%;
                    display: block;
                }
                .print-btn a{
                    background: #CFD8DC;
                    display: inline-block;
                    padding: 3px 13px;
                    border-radius: 5px;
                    color: #000 !important;
                }
                .print-btn a:hover {
                    background: #B0BEC5;
                }
                .invoice-title {
                    text-align: center;
                    font-weight: bold;
                    font-size: 15px;
                    margin-bottom: 15px;
                    padding: 5px;
                    border-top: 1px dotted #454545;
                    border-bottom: 1px dotted #454545;
                }
                .col-xs-12 {
                    width: 100%;
                }
                .col-xs-10 {
                    width: 80%;
                    float: left;
                }
                .col-xs-9 {
                    width: 70%;
                    float: left;
                }
                .col-xs-7 {
                    width: 60%;
                    float: left;
                }
                .col-xs-6 {
                    width: 50%;
                    float: left;
                }
                .col-xs-5 {
                    width: 40%;
                    float: left;
                }
                .col-xs-4 {
                    width: 35%;
                    float: left;
                }
                .col-xs-3 {
                    width: 30%;
                    float: left;
                }
                .col-xs-2 {
                    width: 20%;
                    float: left;
                }
                .b-text {
                    font-weight: 500;
                    font-size: 15px;
                }
                .normal-text {
                    font-size: 14px;
                    margin: 0px;
                }
                .invoice-details {
                    width: 100%;
                    border-collapse: collapse;
                    border:1px solid #ccc;
                }
                .invoice-details thead {
                    font-weight: 500;
                    text-align:center;
                }
                .invoice-details tbody td{
                    padding: 0px 5px;
                }
                .text-center {
                    text-align: center;
                }
                .text-right {
                    text-align: right;
                }
                .line {
                    border-bottom: 1px solid #ccc;
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
                .paid-text {
                    padding:30px 0px;
                }
                .mt-60 {
                    margin-top: 60px;
                }
                .mr-20 {
                    margin-right: 20px;
                }
                .ml-20 {
                    margin-left: 20px;
                }
                .ft-fiext {
                    position: fixed;
                    bottom: 0;
                }
                @media print {
                    .hide {
                        display: none
                    }
                    .v-data-table > .v-data-table__wrapper > table {
                        width: 100%;
                        border-spacing: 0;
                    }
                    .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
                        font-size: 13px;
                        padding: 0px 1px;
                        color: #000;
                        border: 1px solid #ccc;
                        text-align: center;
                        height: 24px !important;
                    }
                    .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td {
                        height: 20px !important;
                        padding: 0px 5px !important;
                        font-size: 12px !important;
                        border: 1px solid #ccc !important;
                    }
                }
                .cls {
                    clear: both;
                }
            `;
            document.head.appendChild(this.style);
        },
    }
}
</script>

<style lang="scss" scoped>
    .v-icon.v-icon {
       font-size: 18px !important;
       top: -3px !important;
    }
    .v-icon.v-icon[data-v-1f38b4e5] {
        font-size: 14px !important;
        top: 0px !important; 
    }
    .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
        font-size: 12px;
        padding: 0px 1px;
        background: #607D8B;
        color: #000;
        border: 1px solid #ccc;
        text-align: center;
        height: 24px !important;
        letter-spacing: .7px;
   }
   .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td {
        height: 20px !important;
        padding: 1px 5px !important;
        font-size: 12px !important;
        border: 1PX solid #ccc !important;
        text-align: center;
   }
</style>